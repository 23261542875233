<template>
    <div class="container">
        <h1>{{$t('signature.heading')}}</h1>
        <loader-overlay
            :loading="loading"
        >
            <div class="p-4 rounded border border-gray bg-white shadow">
                <div v-if="!isSigned">
                    <p v-if="session" class="alert alert-primary">
                        {{$t('signature.content', { phoneNumber: identityPhoneNumber})}}
                    </p>

                    <div
                        class="row pt-3"
                    >
                        <div class="col-12 col-md-8">
                            <sms-signature
                                :phone="identityPhoneNumber || ''"
                                :send-on-mount="true"
                                @signed="onSigned"
                            />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <signed-alert />
                </div>
            </div>
        </loader-overlay>
    </div>
</template>

<script>
import SmsSignature from './Components/SmsSignature.vue'
import SignedAlert from './Components/SignedAlert.vue'

export default {
    name: 'Signature',
    components: {
        SmsSignature,
        SignedAlert
    },
    data () {
        return {
            session: null,
            loading: true
        }
    },
    computed: {
        identityPhoneNumber () {
            return (this.session) ? this.session.identityPhoneNumber : null
        },
        isSigned () {
            return this.session && this.session.isSigned
        }
    },
    beforeMount () {
        this.$api.sessions.isSigneable()
            .catch(() => {
                this.$router.replace({ name: 'Landing' })
            })
    },
    mounted () {
        this.loadSession()
    },
    methods: {
        loadSession () {
            this.loading = true
            this.$api.sessions.read('current')
                .then((response) => {
                    this.session = response.data
                }).catch(error => {
                    console.error(error)
                }).finally(() => {
                    this.$nextTick(() => { this.loading = false })
                })
        },
        onSigned () {
            this.loadSession()
        }
    }
}
</script>
