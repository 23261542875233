<template>
    <div>
        <form @submit.prevent="onSubmit">
            <div :class="{ 'p-4 rounded border border-gray bg-white shadow': styling }">
                <b-form-group
                    :label="$t(inputLabel)"
                >
                    <b-form-input
                        v-model.trim="code"
                    />
                </b-form-group>

                <slot name="notifications" />

                <div class="form-actions">
                    <b-btn
                        type="submit"
                        variant="primary"
                        :disabled="code.length < 4"
                        class="mr-3"
                        size="md"
                    >
                        {{$t(buttonLabel)}}
                    </b-btn>
                    <slot />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'SmsSignatureForm',
    props: {
        inputLabel: {
            type: String,
            required: false,
            default: 'smsForm.label'
        },
        buttonLabel: {
            type: String,
            required: false,
            default: 'smsForm.submit'
        },
        styling: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data () {
        return {
            code: ''
        }
    },
    methods: {
        onSubmit () {
            this.$emit('submit', `${this.code}`)
            this.code = ''
        }
    }
}
</script>
