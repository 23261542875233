<template>
  <loader-overlay
    :loading="(sending || validating) && sent"
    :color="'yellow'"
  >
    <div
      v-if="!verified"
    >
      <sms-signature-form
        @submit="validateCode"
      >
        <template #notifications>
          <div>
            <p
              v-show="sent && !invalidCode"
              class="alert alert-success text-center"
            >
              {{$t('smsForm.messageSent', { phone })}}
            </p>
            <p
              v-show="invalidCode"
              class="alert alert-danger text-center"
            >
              {{$t('smsForm.validationFailed')}}
            </p>
          </div>
        </template>

        <b-btn
          :variant="(sent) ? 'secondary' : 'primary'"
          :disabled="cooldown || sending"
          size="md"
          @click.prevent="issueCode"
        >
            {{$t((sent) ? 'smsForm.sendNewCode' : 'smsForm.sendCode')}}
        </b-btn>
      </sms-signature-form>
    </div>

    <div
      v-else
    >
      <p class="alert alert-success text-center">
        {{$t('smsForm.verifiedMessage')}}
      </p>
    </div>
  </loader-overlay>
</template>

<script>
import SmsSignatureForm from './SmsSignatureForm.vue'

export default {
    name: 'SmsSignature',
    components: {
        SmsSignatureForm
    },
    props: {
        phone: {
            type: String,
            required: true
        },
        sendOnMount: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            sent: false,
            verified: false,
            cooldown: false,
            sending: false,
            validating: false,
            invalidCode: false
        }
    },
    mounted () {
        if (this.sendOnMount && window.sessionStorage.getItem('smsWasSent') !== '1') {
            this.issueCode()
        }
    },
    methods: {
        issueCode () {
            this.cooldown = true
            this.sending = true
            this.invalidCode = false
            setTimeout(() => {
                this.cooldown = false
            }, 5000)

            this.$api.sessions.sendSignatureSms(this.$t('locale'))
                .then(() => {
                    this.sent = true
                    window.sessionStorage.setItem('smsWasSent', '1')
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.sending = false
                    })
                })
        },
        validateCode (code) {
            this.invalidCode = false
            this.validating = true

            this.$api.sessions.validateSignatureSms(code)
                .then(() => {
                    this.verified = true
                    this.$emit('signed')
                })
                .catch((error) => {
                    console.error(error)
                    this.invalidCode = true
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.validating = false
                    })
                })
        }
    }
}
</script>
