<template>
    <div class="alert text-center alert-success">
        <h4>{{$t('smsForm.signedMessage')}}</h4>
        <p class="mb-0">
            {{$t('smsForm.redirectMessage')}}
        </p>
    </div>
</template>

<script>
export default {
    name: 'SignedAlert',
    mounted () {
        window.sessionStorage.setItem('smsWasSent', '0')
        setTimeout(() => {
            window.location = '/redir'
        }, 3000)
    }
}
</script>
